<template>
  <div>
   <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder">
          <div class="card-body ml-2 ">
            <filter-regional v-model:value="regionals" />
          </div>
          <div class="card-body ml-2 mt-2">
          <filter-area v-model:value="areas" v-model:area="areas" />
          </div>
          <div class="card-body ml-2 mt-2">
            <filter-distributor v-model:value="distributor" v-model:distributor="distributors"/>
          </div>
            <div  class="card-body ml-2 mt-2" >
              <a-date-picker  type="date" placeholder="Pick a date"></a-date-picker>
            </div>
          <div class="card-body ml-2 mt-2">
            <a-input-search
                v-model:value="value"
                placeholder="Cari ..."
                style="width: 300px"
                @search="onSearch"
              />
          </div>
        </div>
        <div class="card-body ml-2 mt-2">
          <a-select style="width: 300px" placeholder="Data Kontrak Volume Tidak Ada"></a-select>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder">
          <div class="card-body ml-2 ">
            <filter-province v-model:value="province" v-model:regional="regionals" />
          </div>
          <div class="card-body ml-2 mt-2">
         <filter-kabupaten-kota v-model:value="kabupaten" v-model:area="areas" />
          </div>
          <div class="card-body ml-2 mt-2">
            <a-select
                v-model:value="value1"
                style="width: 300px"
                placeholder="Pilih Toko"
              >
              </a-select>
          </div>
            <div class="card-body ml-2 mt-2">
              <a-date-picker type="date" placeholder="Pick a date"></a-date-picker>
            </div>
            <div class="card-body ml-2 mt-2">
               <a-button type="primary" title="cari" @click="search">
              <i class="fa fa-search" aria-hidden="true"></i>
            </a-button>
            </div>
            <div class="card-body ml-2 mt-3">
                <a-button class="mr-2"  title="download excel" type="primary"  @click="fetchXlsx">
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
              </a-button>

                <a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" v-model:file-list="fileList">
                <a-button>
                  <upload-outlined></upload-outlined>
                </a-button>
              </a-upload>
            </div>
        </div>
      </div>
   </div>
  </div>
</template>

<script>
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'
import { UploadOutlined } from '@ant-design/icons-vue';
export default {
  name: 'MFilter',
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    UploadOutlined,
  },
}
</script>

<style scoped>
.row {
  /* border: 1px dotted gray; */
}
</style>
